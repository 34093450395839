<template>
  <div>
    <FormulateForm
      ref="formSat"
      name="formSat"
    >
      <b-row>
        <b-col md="6">
          <b-card-actions
            no-actions
            :title="$t('Configuração do SAT/MFE')"
          >
            <b-row>
              <b-col md="4">
                <FormulateInput
                  id="pay_box_sat_tab-preferred_invoice_type"
                  v-model="payBox.preferredInvoiceType"
                  type="vue-select"
                  :label="$t('Documento fiscal padrão')"
                  :placeholder="$t('Selecione o documento fiscal padrão')"
                  :options="payBox.availableInvoiceTypes"
                  class="required"
                  validation="required"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <FormulateInput
                  id="pay_box_sat_tab-sat_model"
                  v-model="payBox.sat.satModel"
                  type="vue-select"
                  :label="$t('Modelo')"
                  :placeholder="$t('Selecione um modelo')"
                  :options="getAvailableSatOptions"
                  class="required"
                  validation="required"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <FormulateInput
                  id="pay_box_sat_tab-activate_code"
                  v-model="payBox.sat.activateCode"
                  class="required"
                  type="text"
                  :label="$t('Código de ativação')"
                  validation="required"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <FormulateInput
                  id="pay_box_sat_tab-serial_number"
                  v-model="payBox.sat.serialNumber"
                  class="required"
                  type="text"
                  :label="$t('Número de Série')"
                  validation="required"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <e-button
                  id="activate-sat-btn"
                  variant="outline-primary"
                  :text="$t('Ativar Sat')"
                  :disabled="busy"
                  @click="activateSat"
                />
              </b-col>

              <b-col md="12">
                <FormulateInput
                  id="activate-sat-result"
                  v-model="results.activateSat.msg"
                  type="textarea"
                  disabled="true"
                  :label="$t('Resultado Ativar SAT')"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <p class="h4">
                  {{ $t('Configuração de código de página') }}
                </p>
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="pay_box_sat_tab-use_utf8"
                  v-model="payBox.sat.useUtf8"
                  type="switch"
                  :label="$t('É UTF-8 ?')"
                />
              </b-col>
              <b-col
                v-if="payBox.sat.useUtf8 !== true"
                md="4"
              >
                <FormulateInput
                  id="pay_box_sat_tab-page_code"
                  v-model="payBox.sat.pageCode"
                  type="text"
                  :label="$t('Código de página')"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <p class="h4">
                  {{ $t('Assinatura Comercial') }}
                </p>
              </b-col>
              <b-col md="3">
                <e-button
                  id="generate-signature-sat-btn"
                  variant="outline-primary"
                  :text="$t('Gerar Assinatura')"
                  :disabled="busy"
                  @click="generateSignatureSat"
                />
              </b-col>
              <b-col md="2">
                <FormulateInput
                  v-if="results.generateSignatureSat.error === true"
                  type="label"
                  :label="results.generateSignatureSat.msg"
                />
              </b-col>
              <b-col md="8">
                <FormulateInput
                  id="pay_box_sat_tab-signature_commercial_app"
                  v-model="payBox.sat.signatureCommercialApp"
                  class="required"
                  type="textarea"
                  :label="$t('Hash da Assinatura Comercial')"
                  validation="required"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <e-button
                  id="associate-sat-btn"
                  variant="outline-primary"
                  :text="$t('Associar Assinatura')"
                  :disabled="busy"
                  @click="associateSat"
                />
              </b-col>

              <b-col md="12">
                <FormulateInput
                  id="associate-sat-result"
                  v-model="results.associateSat.msg"
                  type="textarea"
                  disabled="true"
                  :label="$t('Resultado Associar Assinatura')"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <strong class="h3">
                  {{ $t('Impressão') }}
                </strong>
              </b-col>
              <b-col md="3">
                <FormulateInput
                  id="pay_box_sat_tab-margin_top"
                  v-model="payBox.sat.marginTop"
                  type="text"
                  :label="$t('Margem superior')"
                />
              </b-col>
              <b-col md="3">
                <FormulateInput
                  id="pay_box_sat_tab-margin_right"
                  v-model="payBox.sat.marginRight"
                  type="text"
                  :label="$t('Margem direta')"
                />
              </b-col>
              <b-col md="3">
                <FormulateInput
                  id="pay_box_sat_tab-margin_bottom"
                  v-model="payBox.sat.marginBottom"
                  type="text"
                  :label="$t('Margem inferior')"
                />
              </b-col>
              <b-col md="3">
                <FormulateInput
                  id="pay_box_sat_tab-margin_left"
                  v-model="payBox.sat.marginLeft"
                  type="text"
                  :label="$t('Margem esquerda')"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
        <b-col md="6">
          <b-card-actions
            no-actions
            :title="$t('Testes SAT/MFE')"
          >
            <sat-manager />
          </b-card-actions>
        </b-col>
      </b-row>
    </FormulateForm>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { mapState, mapGetters } from 'vuex'
import { alerts } from '@/mixins'
import EButton from '@/views/components/EButton.vue'
import SatManager from '../../SatManager.vue'

export default {
  components: {
    EButton,
    BCardActions,
    BRow,
    BCol,
    SatManager,
  },
  mixins: [alerts],
  data() {
    return {
      hasSignature: false,
      busy: false,
      results: {
        activateSat: {
          msg: null,
          error: true,
        },
        associateSat: {
          msg: null,
          error: true,
        },
        generateSignatureSat: {
          msg: null,
          error: true,
        },
      },
    }
  },
  computed: {
    ...mapState('pages/pdv/payBoxConfiguration', ['payBox', ' ']),
    ...mapGetters('pages/pdv/payBoxConfiguration', [
      'getAvailableSatOptions',
      'invoiceAgentConfigPayload',
    ]),
  },
  methods: {
    skipStep() {
      this.payBox.sat.skipped = true
    },
    canSkip() {
      return this.payBox.sat.skipped
    },
    validateForm() {
      const valid = this.$refs.formSat.isValid
      if (!valid) {
        this.showInvalidDataMessage()
        this.$refs.formSat.showErrors()
      } else {
        this.payBox.sat.skipped = false
      }

      return valid
    },
    async generateSignatureSat() {
      try {
        this.busy = true
        this.results.generateSignatureSat.msg = 'Executando...'

        const associateData = {
          documentSignature: this.payBox.store.document,
        }

        const data = {
          ...this.invoiceAgentConfigPayload,
          request: JSON.stringify(associateData),
        }

        const result = await window.electronAPI.invoice.satGenerateSignature({
          ...data,
        })

        if (result.success) {
          this.payBox.sat.signatureCommercialApp = result.signatureValue
          this.hasSignature = true
        }

        this.results.generateSignatureSat.error = false
        this.busy = false
      } catch (err) {
        console.error('generateSignatureSat:', err)
        this.busy = false
        this.results.generateSignatureSat.error = true
        this.results.generateSignatureSat.msg = err
      }
    },
    async associateSat() {
      try {
        this.busy = true
        this.results.associateSat.msg = 'Executando...'

        const associateData = {
          documentAssociate: this.payBox.store.document,
          signatureValue: this.payBox.sat.signatureCommercialApp,
        }

        const data = {
          ...this.invoiceAgentConfigPayload,
          request: JSON.stringify(associateData),
        }

        const result = await window.electronAPI.invoice.satAssociate({
          ...data,
        })
        this.results.associateSat.error = false
        this.results.associateSat.msg = result || 'ok'
        this.busy = false
      } catch (err) {
        console.error('associateSat:', err)
        this.busy = false
        this.results.associateSat.error = true
        this.results.associateSat.msg = err
      }
    },
    async activateSat() {
      try {
        this.busy = true
        this.results.activateSat.msg = 'Executando...'

        const activateData = {
          cnpjActivation: this.payBox.store.document,
          stateCode: this.payBox.store.address.provinceCode,
        }

        const data = {
          ...this.invoiceAgentConfigPayload,
          request: JSON.stringify(activateData),
        }

        const result = await window.electronAPI.invoice.satActivate({
          ...data,
        })
        this.results.activateSat.error = false
        this.results.activateSat.msg = result || 'ok'
        this.busy = false
      } catch (err) {
        console.error('activateSat:', err)
        this.busy = false
        this.results.activateSat.error = true
        this.results.activateSat.msg = err
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
