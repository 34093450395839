<template>
  <div>
    <b-row>
      <b-col md="12">
        <e-button
          id="operational-check-sat-btn"
          variant="outline-primary"
          :text="$t('Consultar Status Operacional')"
          :disabled="busy"
          @click="checkSatStatus"
        />
      </b-col>

      <b-col md="12">
        <FormulateInput
          id="operational-check-sat-result"
          v-model="results.checkSatStatus.msg"
          type="textarea"
          disabled="true"
          class="required"
          :label="$t('Resultado Consultar Status SAT')"
          validation="checkSatStatusValid"
          :validation-rules="{ checkSatStatusValid: () => !results.checkSatStatus.error }"
          :validation-messages="{
            checkSatStatusValid: $t('Resultado Consultar Status SAT deve ser sucesso'),
          }"
        />
      </b-col>

      <b-col>
        <FormulateInput
          id="operational-check-sat-layout-version"
          v-model="payBox.sat.layoutVersion"
          type="text"
          disabled="true"
          :label="$t('Versão do Layout')"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <e-button
          id="end-to-end-sat-btn"
          variant="outline-primary"
          :text="$t('Realizar Teste Fim a Fim')"
          :disabled="busy"
          @click="endToEndTest"
        />
      </b-col>
      <b-col md="12">
        <FormulateInput
          id="end-to-end-sat-result"
          v-model="results.endToEndTest.msg"
          type="textarea"
          disabled="true"
          class="required"
          :label="$t('Resultado Teste Fim a Fim')"
          validation="endToEndTestValid"
          :validation-rules="{ endToEndTestValid: () => !results.endToEndTest.error }"
          :validation-messages="{
            endToEndTestValid: $t('Resultado Teste Fim a Fim deve ser sucesso'),
          }"
        />
      </b-col>
      <b-col md="12">
        <FormulateInput
          id="end-to-end-sat-result_number_check"
          v-model="randomNumberCheck"
          class="required"
          type="text"
          :label="$t('Código impresso no campo Observação do extrato do SAT')"
          validation="required|endToEndTestCheck"
          :validation-rules="{ endToEndTestCheck: () => randomNumberCheck == randomNumber }"
          :validation-messages="{
            endToEndTestCheck: $t('Último código impresso no campo Observação deve ser informado'),
          }"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <e-button
          id="update-software-sat-btn"
          variant="outline-primary"
          :text="$t('Atualizar software básico')"
          :disabled="busy"
          @click="updateBasicSoftwareSat"
        />
      </b-col>

      <b-col md="12">
        <FormulateInput
          id="update-software-sat-result"
          v-model="results.updateBasicSoftwareSat.msg"
          type="textarea"
          disabled="true"
          :label="$t('Resultado Atualizar software básico')"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <e-button
          id="logs-sat-btn"
          variant="outline-primary"
          :text="$t('Extrair logs')"
          :disabled="busy"
          @click="extractLogsSat"
        />
      </b-col>

      <b-col md="12">
        <FormulateInput
          id="logs-sat-result"
          v-model="results.extractLogsSat.msg"
          type="textarea"
          disabled="true"
          :label="$t('Resultado Extrair logs')"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <e-button
          id="unlock-sat-btn"
          variant="outline-primary"
          :text="$t('Desbloquear SAT')"
          :disabled="busy"
          @click="unlockSat"
        />
      </b-col>

      <b-col md="12">
        <FormulateInput
          id="unlock-sat-result"
          v-model="results.unlockSat.msg"
          type="textarea"
          disabled="true"
          :label="$t('Resultado Desbloquear SAT')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    EButton,
  },
  data() {
    return {
      busy: false,
      randomNumber: null,
      randomNumberCheck: null,
      results: {
        checkSat: {
          msg: null,
          error: true,
        },
        checkSatStatus: {
          msg: null,
          error: true,
        },
        unlockSat: {
          msg: null,
          error: true,
        },
        updateBasicSoftwareSat: {
          msg: null,
          error: true,
        },
        extractLogsSat: {
          msg: null,
          error: true,
        },
        endToEndTest: {
          msg: null,
          error: true,
        },
      },
    }
  },
  computed: {
    ...mapState('pages/pdv/payBoxConfiguration', ['payBox']),
    ...mapGetters('pages/pdv/payBoxConfiguration', ['invoiceAgentConfigPayload']),
  },
  methods: {
    async checkSat() {
      try {
        this.busy = true
        this.results.checkSat.msg = 'Executando...'
        const result = await window.electronAPI.invoice.satCheck(this.invoiceAgentConfigPayload)
        this.results.checkSat.error = false
        this.results.checkSat.msg = result
        this.busy = false
      } catch (err) {
        console.error('checkSat:', err)
        this.busy = false
        this.results.checkSat.error = true
        this.results.checkSat.msg = err
      }
    },
    async checkSatStatus() {
      try {
        this.busy = true
        this.results.checkSatStatus.msg = 'Executando...'
        const result = await window.electronAPI.invoice.satCheckStatus(
          this.invoiceAgentConfigPayload
        )
        this.results.checkSatStatus.error = false
        this.results.checkSatStatus.msg = result.parsedResponse

        if (result.success) {
          this.payBox.sat.layoutVersion = result.layoutVersion
        }

        this.busy = false
      } catch (err) {
        console.error('checkSatStatus:', err)
        this.busy = false
        this.results.checkSatStatus.error = true
        this.results.checkSatStatus.msg = err
      }
    },
    async unlockSat() {
      try {
        this.busy = true
        this.results.unlockSat.msg = 'Executando...'
        const result = await window.electronAPI.invoice.unlockSat(this.invoiceAgentConfigPayload)
        this.results.unlockSat.error = false
        this.results.unlockSat.msg = result
        this.busy = false
      } catch (err) {
        console.error('unlockSat:', err)
        this.busy = false
        this.results.unlockSat.error = true
        this.results.unlockSat.msg = err
      }
    },
    async updateBasicSoftwareSat() {
      try {
        this.busy = true
        this.results.updateBasicSoftwareSat.msg = 'Executando...'
        const result = await window.electronAPI.invoice.updateBasicSoftwareSat(
          this.invoiceAgentConfigPayload
        )
        this.results.updateBasicSoftwareSat.error = false
        this.results.updateBasicSoftwareSat.msg = result
        this.busy = false
      } catch (err) {
        console.error('updateBasicSoftwareSat:', err)
        this.busy = false
        this.results.updateBasicSoftwareSat.error = true
        this.results.updateBasicSoftwareSat.msg = err
      }
    },
    async extractLogsSat() {
      try {
        this.busy = true
        this.results.extractLogsSat.msg = 'Executando...'
        const result = await window.electronAPI.invoice.extractLogsSat(
          this.invoiceAgentConfigPayload
        )
        this.results.extractLogsSat.error = false
        this.results.extractLogsSat.msg = result
        this.busy = false
      } catch (err) {
        console.error('extractLogsSat:', err)
        this.busy = false
        this.results.extractLogsSat.error = true
        this.results.extractLogsSat.msg = err
      }
    },
    async endToEndTest() {
      try {
        const max = 999
        const min = 100
        this.randomNumber = Math.floor(Math.random() * (max - min) + min)
        console.debug('testPrint | randomNumber', this.randomNumber)
        this.busy = true
        this.results.endToEndTest.msg = 'Executando...'

        const endToEndData = {
          aleatoryCode: `${this.randomNumber}`,
          storeCnpj: this.payBox.store.document,
          stateRegistration: this.payBox.store.stateRegistration,
          signatureCommercialApp: this.payBox.sat.signatureCommercialApp,
        }

        const data = {
          ...this.invoiceAgentConfigPayload,
          request: JSON.stringify(endToEndData),
        }

        const result = await window.electronAPI.invoice.satTestEndToEnd({
          ...data,
        })
        this.results.endToEndTest.error = false
        this.results.endToEndTest.msg = result || 'ok'
        this.busy = false
      } catch (err) {
        console.error('endToEndTest:', err)
        this.busy = false
        this.results.endToEndTest.error = true
        this.results.endToEndTest.msg = err
      }
    },
  },
}
</script>
