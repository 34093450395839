<template>
  <div>
    <FormulateForm
      ref="formExpeditionPrinter"
      name="formExpeditionPrinter"
    >
      <b-row>
        <b-col md="6">
          <b-card-actions
            no-actions
            :title="$t('Dados da impressora térmica da Expedição')"
            :show-loading="loadingPrintList"
          >
            <b-row>
              <b-col md="12">
                <FormulateInput
                  id="pay_box_expedition_printer_tab-thermal_printer_customer_copy"
                  v-model="payBox.thermalPrinterCustomerCopy"
                  name="thermalPrinterCustomerCopy"
                  type="switch"
                  :instruction="$t('Se habilitado, usará as configurações da impressora deste computador para impressão da via do cliente para retiradas em balcão.')"
                  :label="$t('Habilitar impressão da via do cliente para retirada?')"
                />
              </b-col>
              <b-col md="12">
                <FormulateInput
                  id="pay_box_expedition_printer_tab-expedition_thermal_printer"
                  v-model="payBox.expeditionThermalPrinter"
                  name="expeditionThermalPrinter"
                  type="switch"
                  :instruction="$t('Se habilitado, mostra campos para configurar a impressora da expedição.')
                  "
                  :label="$t('Possui Impressora de Expedição?')"
                />
              </b-col>
            </b-row>

            <template v-if="hasExpeditionThermalPrinter">
              <b-row>
                <b-col md="12">
                  <FormulateInput
                    id="pay_box_expedition_printer_tab-auto_identify_conf"
                    v-model="payBox.thermalPrinterExpedition.autoIdentifyConf"
                    type="switch"
                    :label="$t('Identificar Automaticamente as Configurações')"
                  />
                </b-col>

                <b-col md="4">
                  <FormulateInput
                    id="pay_box_expedition_printer_tab-printer_port"
                    v-model="localFormData.printerPortSelected"
                    name="printerPortSelected"
                    type="vue-select"
                    :label="$t('Impressora térmica')"
                    :placeholder="$t('Selecione a impressora')"
                    :options="getOptionsPrinter"
                    :class="isAutoIdentifyConf ? '' : 'required'"
                    :validation="isAutoIdentifyConf ? '' : 'required'"
                    @input="onSelectPrinterPort"
                  />
                </b-col>
                <b-col md="4">
                  <e-button
                    id="list-printers-btn"
                    variant="outline-primary"
                    :text="$t('Listar impressoras')"
                    @click="getPrintersList"
                  />
                </b-col>

                <b-col md="12">
                  <p
                    v-if="listPrinterError"
                    class="h6 red"
                  >
                    Erro ao listar impressoras: {{ listPrinterError }}
                  </p>
                </b-col>

                <b-col
                  v-if="showNetworkPrinterField || showLocalPrinterField"
                  md="12"
                >
                  <FormulateInput
                    v-if="showLocalPrinterField"
                    id="pay_box_expedition_printer_tab-printer_port_text"
                    v-model="localFormData.printerPort"
                    name="localPrinterPort"
                    type="text"
                    :label="$t('Caminho da impressora local')"
                    :class="isAutoIdentifyConf ? '' : 'required'"
                    :validation="isAutoIdentifyConf ? '' : 'required'"
                    @blur="onBlurPrinterPort"
                  />
                  <FormulateInput
                    v-if="showNetworkPrinterField"
                    id="pay_box_expedition_printer_tab-printer_port_text"
                    v-model="localFormData.printerPort"
                    name="localPrinterPort"
                    type="text"
                    :label="$t('Endereço IP')"
                    :class="isAutoIdentifyConf ? '' : 'required'"
                    :validation="`${isAutoIdentifyConf ? '' : 'required|'}ipAddress`"
                    @blur="onBlurPrinterPort"
                  />
                  <!--  -->
                </b-col>
              </b-row>

              <b-row>
                <b-col md="8">
                  <FormulateInput
                    id="pay_box_expedition_printer_tab-printer_model"
                    v-model="payBox.thermalPrinterExpedition.model"
                    type="vue-select"
                    :label="$t('Modelo')"
                    :placeholder="$t('Selecione o modelo')"
                    :options="optionsPrinterModel()"
                  />
                </b-col>

                <b-col md="4">
                  <FormulateInput
                    id="pay_box_expedition_printer_tab-printer_page_code"
                    v-model="payBox.thermalPrinterExpedition.pageCode"
                    type="vue-select"
                    :label="$t('Código de página')"
                    :placeholder="$t('Selecione o código de página')"
                    :options="optionsPrinterPageCode()"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <FormulateInput
                    id="pay_box_expedition_printer_tab-lines_between_coupons"
                    v-model="payBox.thermalPrinterExpedition.linesBetweenCoupons"
                    type="number"
                    :label="$t('Linhas entre cupons')"
                  />
                </b-col>

                <b-col md="3">
                  <FormulateInput
                    id="pay_box_expedition_printer_tab-space_between_lines"
                    v-model="payBox.thermalPrinterExpedition.spaceBetweenLines"
                    type="number"
                    :label="$t('Linhas entre linhas')"
                  />
                </b-col>

                <b-col md="6">
                  <FormulateInput
                    id="pay_box_expedition_printer_tab-columns_normal_font"
                    v-model="payBox.thermalPrinterExpedition.columnsNormalFont"
                    type="number"
                    :label="$t('Quantidade de colunas para fonte normal')"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <FormulateInput
                    id="pay_box_expedition_printer_tab-verify_printer"
                    v-model="payBox.thermalPrinterExpedition.verifyPrinter"
                    type="switch"
                    :label="$t('Verificar impressora')"
                  />
                </b-col>
                <b-col md="6">
                  <FormulateInput
                    id="pay_box_expedition_printer_tab-control_port"
                    v-model="payBox.thermalPrinterExpedition.controlPort"
                    type="switch"
                    :label="$t('Controle de porta')"
                  />
                </b-col>
              </b-row>
            </template>
          </b-card-actions>
        </b-col>

        <b-col
          v-if="hasExpeditionThermalPrinter"
          md="6"
        >
          <b-card-actions
            no-actions
            :title="$t('Teste impressora térmica da Expedição')"
          >
            <thermal-printer
              printer-type="Expedition"
              :show-cash-drawer-test="false"
            />
          </b-card-actions>
        </b-col>
      </b-row>
    </FormulateForm>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { alerts, payBoxConfigurationTypes } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import EButton from '@/views/components/EButton.vue'
import ThermalPrinter from '../../ThermalPrinter.vue'

const defaultOptionsPrinter = ['Outro']

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    ThermalPrinter,
    EButton,
  },
  mixins: [alerts, payBoxConfigurationTypes],
  data() {
    return {
      loadingPrintList: false,
      localFormData: {
        printerPortSelected: null,
        printerPort: null,
      },
      optionsPrinter: defaultOptionsPrinter,
      listPrinterError: undefined,
    }
  },
  computed: {
    ...mapState('pages/pdv/payBoxConfiguration', ['payBox']),

    hasExpeditionThermalPrinter() {
      return this.payBox.expeditionThermalPrinter
    },

    getOptionsPrinter() {
      return this.getOptionsPrinterFormated(this.optionsPrinter)
    },

    isAutoIdentifyConf() {
      return this.payBox.expeditionThermalPrinter.autoIdentifyConf
    },

    showNetworkPrinterField() {
      return (
        this.localFormData.printerPortSelected === this.defaultOptionsPrinterEnum.NETWORK_PRINTER
      )
    },
    showLocalPrinterField() {
      return (
        this.localFormData.printerPortSelected === this.defaultOptionsPrinterEnum.OTHER_LOCAL_PORT
      )
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    'payBox.thermalPrinterExpedition.port': function (val) {
      if (val) {
        this.localFormData.printerPort = this.formatToLocalPrinterPort(val)
        if (this.optionsPrinter.length > 0) this.setPrinterPortSelected()
      }
    },
  },

  async mounted() {
    await this.getPrintersList()
  },

  methods: {
    ...mapActions('pages/pdv/payBoxConfiguration', ['setPayBoxThermalPrinterExpeditionPort']),

    skipStep() {
      this.payBox.thermalPrinterExpedition.skipped = true
    },
    canSkip() {
      return this.payBox.thermalPrinterExpedition.skipped
    },
    validateForm() {
      const valid = this.$refs.formExpeditionPrinter.isValid
      if (!valid) {
        this.showInvalidDataMessage()
        this.$refs.formExpeditionPrinter.showErrors()
      } else {
        this.payBox.thermalPrinterExpedition.skipped = false
      }

      return valid
    },

    onSelectPrinterPort(value) {
      const isDefaultPrinterOption =
        value === this.defaultOptionsPrinterEnum.NETWORK_PRINTER ||
        value === this.defaultOptionsPrinterEnum.OTHER_LOCAL_PORT
      if (!isDefaultPrinterOption) {
        this.localFormData.printerPort = value
        this.setPayBoxThermalPrinterExpeditionPort(value)
      }
    },
    onBlurPrinterPort(event) {
      this.setPayBoxThermalPrinterExpeditionPort(this.formatToStatePrinterPort(event.target.value))
    },
    setPrinterPortSelected() {
      const localPrinterPort = this.localFormData.printerPort
      const foundInPrinterList = this.optionsPrinter.find(p => p === localPrinterPort)

      this.localFormData.printerPortSelected = this.getOptionSelected(
        localPrinterPort,
        foundInPrinterList,
        this.localFormData.printerPortSelected
      )
    },

    async getPrintersList() {
      try {
        this.loadingPrintList = true
        this.optionsPrinter = await window.electronAPI.listPrinters()
        this.listPrinterError = null
      } catch (err) {
        console.error('printersListInExpedition:', err)
        this.listPrinterError = err
      } finally {
        this.loadingPrintList = false
      }

      this.setPrinterPortSelected()
    },
  },
}
</script>

<style lang="scss" scoped></style>
