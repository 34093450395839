<template>
  <div>
    <b-row>
      <b-col>
        <!-- TODO colocar cards dos steps que possuem os inputs apenas em modo de visualização -->
        <!-- nos cards dos inputs terão um campo com "health check" exibindo status ok | failed | not tested -->
      </b-col>
    </b-row>
    <b-card-actions no-actions>
      <div class="d-flex align-items-center justify-content-center">
        <p class="h4">
          Verificação dos dados
        </p>
      </div>
    </b-card-actions>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
  },
}
</script>

<style lang="scss" scoped></style>
