<template>
  <section>
    <form-wizard
      ref="payboxSetupWizard"
      :title="configurationTitle"
      subtitle=""
      :next-button-text="$t('Próximo')"
      :back-button-text="$t('Voltar')"
      :finish-button-text="$t('Finalizar')"
      step-size="sm"
      shape="circle"
      :color="style.collor"
      :start-index="tabStartIndex"
      @on-complete="onFinishWizard"
    >
      <tab-content
        v-for="(tab, index) in tabList"
        :key="index"
        v-bind="tab.tabContentProps"
      >
        <component
          :is="tab.component"
          v-bind="tab.componentProps"
        />
      </tab-content>

      <template #custom-buttons-left="props">
        <e-button
          :text="$t('Cancelar')"
          variant="danger"
          @click="onCancelConfiguration"
        />
        <e-button
          v-if="canSkipCurrentTab(props.activeTabIndex)"
          style="margin-left: 5px"
          :text="$t('Pular')"
          variant="light"
          @click="onSkipConfiguration(props.activeTabIndex)"
        />
      </template>
    </form-wizard>
  </section>
</template>

<script>
import { app, loadingOverlay, payboxConfiguration } from '@/mixins'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import EButton from '@/views/components/EButton.vue'
import { mapState, mapActions } from 'vuex'
import StartTab from './components/StartTab.vue'
import PayBoxDataTab from './components/PayBoxDataTab.vue'
import SatDeviceTab from './components/SatDeviceTab.vue'
import PrinterDeviceTab from './components/PrinterDeviceTab.vue'
import SummaryConfigurationTab from './components/SummaryConfigurationTab.vue'
import TaxCouponTab from './components/TaxCouponTab.vue'
import TefDeviceTab from './components/TefDeviceTab.vue'
import ExpeditionPrinterDeviceTab from './components/ExpeditionPrinterDeviceTab.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    StartTab,
    PayBoxDataTab,
    SatDeviceTab,
    PrinterDeviceTab,
    ExpeditionPrinterDeviceTab,
    EButton,
    SummaryConfigurationTab,
    TaxCouponTab,
    TefDeviceTab,
  },

  mixins: [app, payboxConfiguration, loadingOverlay],
  async beforeRouteLeave(to, from, next) {
    next()
    // ensure to check config again when user is leaving the page
    await this.checkPdvCurrentConfiguration()
  },
  data() {
    return {
      tabStartIndex: 0,
    }
  },

  computed: {
    ...mapState('pages/pdv/payBoxConfiguration', ['payBox', 'invoiceEnvironment']),
    configurationTitle() {
      const environment = window.env || 'local'
      if (environment !== 'production') {
        return `${this.$t(
          'Configuração do PDV'
        )} | Environment: '${environment}' | Invoice Environment: '${this.invoiceEnvironment}'`
      }

      return this.$t('Configuração do PDV')
    },

    tabList() {
      const tabs = [
        {
          tabContentProps: {
            title: this.$t('Início'),
          },
          component: 'start-tab',
          componentProps: {},
          show: true,
          canSkip: false,
        },
        {
          tabContentProps: {
            title: this.$t('Dados do PDV'),
            beforeChange: this.onChangePdvDataTab,
          },
          component: 'pay-box-data-tab',
          componentProps: {
            ref: 'payBoxDataTab',
          },
          show: true,
          canSkip: false,
        },
        {
          tabContentProps: {
            title: this.$t('Impressoras'),
            beforeChange: this.onChangePrinterTab,
          },
          component: 'printer-device-tab',
          componentProps: {
            ref: 'payBoxPrinterTab',
          },
          show: true,
          canSkip: true,
        },
        {
          tabContentProps: {
            title: this.$t('Impressora Expedição'),
            beforeChange: this.onChangePrinterExpeditionTab,
          },
          component: 'expedition-printer-device-tab',
          componentProps: {
            ref: 'payBoxExpeditionPrinterTab',
          },
          show: true,
          canSkip: true,
        },
        {
          tabContentProps: {
            title: this.$t('TEF'),
            beforeChange: this.onChangeTefTab,
          },
          component: 'tef-device-tab',
          componentProps: {
            ref: 'payBoxTefTab',
          },
          show: true,
          canSkip: true,
        },
        {
          tabContentProps: {
            title: this.$t('SAT/MFE'),
            beforeChange: this.onChangeSatTab,
          },
          component: 'sat-device-tab',
          componentProps: {
            ref: 'payBoxSatTab',
          },
          show: this.payBox.availableInvoiceTypes?.includes('SAT'),
          canSkip: true,
        },
        {
          tabContentProps: {
            title: this.$t('NFC-e'),
            beforeChange: this.onChangeNFCeTab,
          },
          component: 'tax-coupon-tab',
          componentProps: {
            ref: 'payBoxNFCeTab',
          },
          show: true,
          canSkip: true,
        },
        {
          tabContentProps: {
            title: this.$t('Verificação dos dados'),
          },
          component: 'summary-configuration-tab',
          componentProps: {},
          show: true,
          canSkip: false,
        },
      ]
        .filter(t => t.show)
        .map((objTab, index) => ({
          ...objTab,
          tabIndex: index,
        }))

      return tabs
    },
  },

  created() {
    if (this.payBox.finishedSetup === true) {
      this.tabStartIndex = this.tabList.length - 1
    }
  },

  methods: {
    ...mapActions('pages/pdv/payBoxConfiguration', ['finishPayBoxSetup', 'fetchPayBox']),
    canSkipCurrentTab(activeTabIndex) {
      const activeTab = this.tabList.filter(i => i.tabIndex === activeTabIndex)[0]
      return activeTab.canSkip
    },
    onChangePdvDataTab() {
      return this.$refs.payBoxDataTab.validateForm()
    },
    onChangeSatTab() {
      const formResult = this.$refs.payBoxSatTab.validateForm()
      return this.$refs.payBoxSatTab.canSkip() || formResult
    },
    onChangeTefTab() {
      const formResult = this.$refs.payBoxTefTab.validateForm()
      return this.$refs.payBoxTefTab.canSkip() || formResult
    },
    onChangeNFCeTab() {
      const formResult = this.$refs.payBoxNFCeTab.validateForm()
      return this.$refs.payBoxNFCeTab.canSkip() || formResult
    },
    onChangePrinterTab() {
      const formResult = this.$refs.payBoxPrinterTab.validateForm()
      return this.$refs.payBoxPrinterTab.canSkip() || formResult
    },
    onChangePrinterExpeditionTab() {
      const formResult = this.$refs.payBoxExpeditionPrinterTab.validateForm()
      return this.$refs.payBoxExpeditionPrinterTab.canSkip() || formResult
    },
    async onFinishWizard() {
      try {
        const confirmed = await this.confirm({
          title: 'Finalizar ?',
        })

        if (!confirmed) return

        await this.finishPayBoxSetup()

        this.showSuccess({
          message: this.$t('PDV configurado com sucesso'),
        })

        this.$router.back()
      } catch (error) {
        console.error(error)
        this.showError({ error })
      }
    },

    onSkipConfiguration(activeTabIndex) {
      const activeTab = this.tabList.filter(i => i.tabIndex === activeTabIndex)[0]

      if (!activeTab.canSkip) {
        return
      }

      switch (activeTab.component) {
        case 'printer-device-tab':
          this.$refs.payBoxPrinterTab.skipStep()
          break
        case 'expedition-printer-device-tab':
          this.$refs.payBoxExpeditionPrinterTab.skipStep()
          break
        case 'tef-device-tab':
          this.$refs.payBoxTefTab.skipStep()
          break
        case 'sat-device-tab':
          this.$refs.payBoxSatTab.skipStep()
          break
        case 'tax-coupon-tab':
          this.$refs.payBoxNFCeTab.skipStep()
          break
        default:
          throw new Error(`componet ${activeTab.component} not implemented`)
      }
      this.$refs.payboxSetupWizard.nextTab()
    },

    async onCancelConfiguration() {
      try {
        const confirmed = await this.confirm({
          title: 'Cancelar configuração?',
        })

        if (!confirmed) return
        // revert store to current PayBox state
        await this.fetchPayBox()

        this.$router.back()
      } catch (error) {
        console.error(error)
        this.showError({ error })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
