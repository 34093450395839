<template>
  <div>
    <div>
      Versions:
      <pre> {{ versions }} </pre>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      versions: 'pending',
    }
  },
  mounted() {
    this.versions = JSON.stringify(window.electronAPP.versions(), null, 2)
  },
}
</script>
