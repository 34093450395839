var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-row", [_c("b-col")], 1),
      _c("b-card-actions", { attrs: { "no-actions": "" } }, [
        _c(
          "div",
          { staticClass: "d-flex align-items-center justify-content-center" },
          [_c("p", { staticClass: "h4" }, [_vm._v(" Verificação dos dados ")])]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }