<template>
  <div>
    <FormulateForm
      ref="formPrinter"
      name="formPrinter"
    >
      <b-row>
        <b-col md="6">
          <b-card-actions
            no-actions
            :title="$t('Dados da impressora térmica')"
            :show-loading="loadingPrintList"
          >
            <b-row>
              <b-col md="12">
                <FormulateInput
                  id="pay_box_printer_tab-auto_identify_conf"
                  v-model="payBox.thermalPrinter.autoIdentifyConf"
                  type="switch"
                  :label="$t('Identificar Automaticamente as Configurações')"
                />
              </b-col>

              <b-col md="4">
                <FormulateInput
                  id="pay_box_printer_tab-printer_port"
                  v-model="localFormData.printerPortSelected"
                  name="printerPortSelected"
                  type="vue-select"
                  :label="$t('Impressora térmica')"
                  :placeholder="$t('Selecione a impressora')"
                  :options="getOptionsPrinter"
                  :class="isAutoIdentifyConf ? '' : 'required'"
                  :validation="isAutoIdentifyConf ? '' : 'required'"
                  @input="onSelectPrinterPort"
                />
              </b-col>
              <b-col md="4">
                <e-button
                  id="list-printers-btn"
                  variant="outline-primary"
                  :text="$t('Listar impressoras')"
                  @click="getPrintersList"
                />
              </b-col>

              <b-col md="12">
                <p
                  v-if="listPrinterError"
                  class="h6 red"
                >
                  Erro ao listar impressoras: {{ listPrinterError }}
                </p>
              </b-col>

              <b-col
                v-if="showNetworkPrinterField || showLocalPrinterField"
                md="12"
              >
                <FormulateInput
                  v-if="showLocalPrinterField"
                  id="pay_box_printer_tab-printer_port_text"
                  v-model="localFormData.printerPort"
                  name="localPrinterPort"
                  type="text"
                  :label="$t('Caminho da impressora local')"
                  :class="isAutoIdentifyConf ? '' : 'required'"
                  :validation="isAutoIdentifyConf ? '' : 'required'"
                  @blur="onBlurPrinterPort"
                />
                <FormulateInput
                  v-if="showNetworkPrinterField"
                  id="pay_box_printer_tab-printer_port_text"
                  v-model="localFormData.printerPort"
                  name="localPrinterPort"
                  type="text"
                  :label="$t('Endereço IP')"
                  :class="isAutoIdentifyConf ? '' : 'required'"
                  :validation="`${isAutoIdentifyConf ? '' : 'required|'}ipAddress`"
                  @blur="onBlurPrinterPort"
                />
                <!--  -->
              </b-col>
            </b-row>
            <b-row>
              <b-col md="8">
                <FormulateInput
                  id="pay_box_printer_tab-printer_model"
                  v-model="payBox.thermalPrinter.model"
                  type="vue-select"
                  :label="$t('Modelo')"
                  :placeholder="$t('Selecione o modelo')"
                  :options="optionsPrinterModel()"
                />
              </b-col>

              <b-col md="4">
                <FormulateInput
                  id="pay_box_printer_tab-printer_page_code"
                  v-model="payBox.thermalPrinter.pageCode"
                  type="vue-select"
                  :label="$t('Código de página')"
                  :placeholder="$t('Selecione o código de página')"
                  :options="optionsPrinterPageCode()"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <FormulateInput
                  id="pay_box_printer_tab-lines_between_coupons"
                  v-model="payBox.thermalPrinter.linesBetweenCoupons"
                  type="number"
                  :label="$t('Linhas entre cupons')"
                />
              </b-col>

              <b-col md="3">
                <FormulateInput
                  id="pay_box_printer_tab-space_between_lines"
                  v-model="payBox.thermalPrinter.spaceBetweenLines"
                  type="number"
                  :label="$t('Linhas entre linhas')"
                />
              </b-col>

              <b-col md="6">
                <FormulateInput
                  id="pay_box_printer_tab-columns_normal_font"
                  v-model="payBox.thermalPrinter.columnsNormalFont"
                  type="number"
                  :label="$t('Quantidade de colunas para fonte normal')"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <FormulateInput
                  id="pay_box_printer_tab-verify_printer"
                  v-model="payBox.thermalPrinter.verifyPrinter"
                  type="switch"
                  :label="$t('Verificar impressora')"
                />
              </b-col>
              <b-col md="6">
                <FormulateInput
                  id="pay_box_printer_tab-control_port"
                  v-model="payBox.thermalPrinter.controlPort"
                  type="switch"
                  :label="$t('Controle de porta')"
                />
              </b-col>
            </b-row>
            <br>

            <b-row>
              <b-col md="12">
                <p class="h4">
                  {{ $t('Gaveta') }}
                </p>
              </b-col>

              <b-col md="6">
                <FormulateInput
                  id="pay_box_printer_tab-cash_drawer_enabled"
                  v-model="payBox.thermalPrinter.cashDrawerEnabled"
                  type="switch"
                  :label="$t('Utilizar gaveta')"
                />
              </b-col>

              <b-col md="6">
                <FormulateInput
                  id="pay_box_printer_tab-cash_drawer_inverted_sign"
                  v-model="payBox.thermalPrinter.cashDrawerInvertedSign"
                  type="switch"
                  :label="$t('Sinal invertido gaveta')"
                />
              </b-col>

              <b-col md="6">
                <FormulateInput
                  id="pay_box_printer_tab-cash_drawer_time_on"
                  v-model="payBox.thermalPrinter.cashDrawerTimeOn"
                  :label="$t('Tempo liga')"
                />
              </b-col>
              <b-col md="6">
                <FormulateInput
                  id="pay_box_printer_tab-cash_drawer_time_off"
                  v-model="payBox.thermalPrinter.cashDrawerTimeOff"
                  :label="$t('Tempo desliga')"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
        <b-col md="6">
          <b-card-actions
            no-actions
            :title="$t('Teste impressora térmica')"
          >
            <thermal-printer printer-type="Local" />
          </b-card-actions>
        </b-col>
      </b-row>
    </FormulateForm>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { alerts, payBoxConfigurationTypes } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import EButton from '@/views/components/EButton.vue'
import ThermalPrinter from '../../ThermalPrinter.vue'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    ThermalPrinter,
    EButton,
  },
  mixins: [alerts, payBoxConfigurationTypes],
  data() {
    return {
      loadingPrintList: false,
      localFormData: {
        printerPortSelected: null,
        printerPort: null,
      },
      optionsPrinter: [],
      listPrinterError: undefined,
    }
  },

  computed: {
    ...mapState('pages/pdv/payBoxConfiguration', ['payBox']),

    getOptionsPrinter() {
      return this.getOptionsPrinterFormated(this.optionsPrinter)
    },

    isAutoIdentifyConf() {
      return this.payBox.thermalPrinter.autoIdentifyConf
    },

    showNetworkPrinterField() {
      return (
        this.localFormData.printerPortSelected === this.defaultOptionsPrinterEnum.NETWORK_PRINTER
      )
    },
    showLocalPrinterField() {
      return (
        this.localFormData.printerPortSelected === this.defaultOptionsPrinterEnum.OTHER_LOCAL_PORT
      )
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    'payBox.thermalPrinter.port': function (val) {
      if (val) {
        this.localFormData.printerPort = this.formatToLocalPrinterPort(val)
        if (this.optionsPrinter.length > 0) this.setPrinterPortSelected()
      }
    },
  },

  async mounted() {
    await this.getPrintersList()
  },

  methods: {
    ...mapActions('pages/pdv/payBoxConfiguration', ['setPayBoxThermalPrinterPort']),

    skipStep() {
      this.payBox.thermalPrinter.skipped = true
    },
    canSkip() {
      return this.payBox.thermalPrinter.skipped
    },
    validateForm() {
      const valid = this.$refs.formPrinter.isValid
      if (!valid) {
        this.showInvalidDataMessage()
        this.$refs.formPrinter.showErrors()
      } else {
        this.payBox.thermalPrinter.skipped = false
      }

      return valid
    },

    onSelectPrinterPort(value) {
      const isDefaultPrinterOption =
        value === this.defaultOptionsPrinterEnum.NETWORK_PRINTER ||
        value === this.defaultOptionsPrinterEnum.OTHER_LOCAL_PORT
      if (!isDefaultPrinterOption) {
        this.localFormData.printerPort = value
        this.setPayBoxThermalPrinterPort(value)
      }
    },
    onBlurPrinterPort(event) {
      this.setPayBoxThermalPrinterPort(this.formatToStatePrinterPort(event.target.value))
    },
    setPrinterPortSelected() {
      const localPrinterPort = this.localFormData.printerPort
      const foundInPrinterList = this.optionsPrinter.find(p => p === localPrinterPort)

      this.localFormData.printerPortSelected = this.getOptionSelected(
        localPrinterPort,
        foundInPrinterList,
        this.localFormData.printerPortSelected
      )
    },

    async getPrintersList() {
      try {
        this.loadingPrintList = true
        this.optionsPrinter = await window.electronAPI.listPrinters()
        this.listPrinterError = null
      } catch (err) {
        console.error('printersList:', err)
        this.listPrinterError = err
      } finally {
        this.loadingPrintList = false
      }

      this.setPrinterPortSelected()
    },
  },
}
</script>

<style lang="scss" scoped></style>
