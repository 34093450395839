var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("e-button", {
                attrs: {
                  id: "pay_box_printer_tab-print_test_btn",
                  variant: "outline-primary",
                  text: _vm.$t("Impressão de teste"),
                  disabled: _vm.busy,
                },
                on: { click: _vm.testPrint },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "pay_box_printer_tab-print_test_result",
                  name: "printTest",
                  type: "textarea",
                  disabled: "true",
                  label: _vm.$t("Resultado Impressão de teste"),
                  validation: "printTestValid",
                  "validation-rules": {
                    printTestValid: function () {
                      return !_vm.results.printTest.error
                    },
                  },
                  "validation-messages": {
                    printTestValid: _vm.$t(
                      "Resultado Impressão de teste deve ser sucesso"
                    ),
                  },
                },
                model: {
                  value: _vm.results.printTest.msg,
                  callback: function ($$v) {
                    _vm.$set(_vm.results.printTest, "msg", $$v)
                  },
                  expression: "results.printTest.msg",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "pay_box_printer_tab-random_number_check",
                  name: "randomNumberCheck",
                  type: "text",
                  label: _vm.$t("Código impresso"),
                  validation: "required|printTestCheck",
                  "validation-rules": {
                    printTestCheck: function () {
                      return _vm.randomNumberCheck == _vm.randomNumber
                    },
                  },
                  "validation-messages": {
                    printTestCheck: _vm.$t(
                      "Último código impresso de teste deve ser informado"
                    ),
                  },
                },
                model: {
                  value: _vm.randomNumberCheck,
                  callback: function ($$v) {
                    _vm.randomNumberCheck = $$v
                  },
                  expression: "randomNumberCheck",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c("br"),
      _vm.showCashDrawerTest
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("e-button", {
                    attrs: {
                      id: "open-cash-drawer-btn",
                      variant: "outline-primary",
                      text: _vm.$t("Abrir gaveta"),
                      disabled: _vm.busy,
                    },
                    on: { click: _vm.openCashDrawer },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "check-cash-drawer-result",
                      name: "cashDrawer",
                      type: "textarea",
                      disabled: "true",
                      label: _vm.$t("Resultado Abrir Gaveta"),
                    },
                    model: {
                      value: _vm.results.cashDrawer.msg,
                      callback: function ($$v) {
                        _vm.$set(_vm.results.cashDrawer, "msg", $$v)
                      },
                      expression: "results.cashDrawer.msg",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showTagsList
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("e-button", {
                    attrs: {
                      id: "open-printer-tags-btn",
                      variant: "outline-primary",
                      text: _vm.$t("Listar tags"),
                      disabled: _vm.busy,
                    },
                    on: { click: _vm.getPrintersTags },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "check-printer-tags-result",
                      name: "printerTags",
                      type: "textarea",
                      disabled: "true",
                      label: _vm.$t("Resultado Listar tags"),
                    },
                    model: {
                      value: _vm.results.printerTags.msg,
                      callback: function ($$v) {
                        _vm.$set(_vm.results.printerTags, "msg", $$v)
                      },
                      expression: "results.printerTags.msg",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }