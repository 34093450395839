var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "form-wizard",
        {
          ref: "payboxSetupWizard",
          attrs: {
            title: _vm.configurationTitle,
            subtitle: "",
            "next-button-text": _vm.$t("Próximo"),
            "back-button-text": _vm.$t("Voltar"),
            "finish-button-text": _vm.$t("Finalizar"),
            "step-size": "sm",
            shape: "circle",
            color: _vm.style.collor,
            "start-index": _vm.tabStartIndex,
          },
          on: { "on-complete": _vm.onFinishWizard },
          scopedSlots: _vm._u([
            {
              key: "custom-buttons-left",
              fn: function (props) {
                return [
                  _c("e-button", {
                    attrs: { text: _vm.$t("Cancelar"), variant: "danger" },
                    on: { click: _vm.onCancelConfiguration },
                  }),
                  _vm.canSkipCurrentTab(props.activeTabIndex)
                    ? _c("e-button", {
                        staticStyle: { "margin-left": "5px" },
                        attrs: { text: _vm.$t("Pular"), variant: "light" },
                        on: {
                          click: function ($event) {
                            return _vm.onSkipConfiguration(props.activeTabIndex)
                          },
                        },
                      })
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        _vm._l(_vm.tabList, function (tab, index) {
          return _c(
            "tab-content",
            _vm._b({ key: index }, "tab-content", tab.tabContentProps, false),
            [
              _c(
                tab.component,
                _vm._b(
                  { tag: "component" },
                  "component",
                  tab.componentProps,
                  false
                )
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }