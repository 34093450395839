var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "FormulateForm",
        { ref: "formTef", attrs: { name: "formTef" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-card-actions",
                    {
                      attrs: {
                        "no-actions": "",
                        title: _vm.$t("Configuração do TEF"),
                      },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c("p", { staticClass: "h4" }, [
                              _vm._v(" " + _vm._s(_vm.$t("Conexão")) + " "),
                            ]),
                          ]),
                          _c(
                            "b-col",
                            { attrs: { md: "2" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "tef-config-connection-https",
                                  type: "switch",
                                  label: _vm.$t("HTTPS"),
                                },
                                model: {
                                  value: _vm.payBox.tef.connectionHttps,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox.tef,
                                      "connectionHttps",
                                      $$v
                                    )
                                  },
                                  expression: "payBox.tef.connectionHttps",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "10" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "tef-config-pinpad-model",
                                  label: _vm.$t("Modelo PINPAD"),
                                  type: "vue-select",
                                  options: _vm.availablePinpadModels,
                                  validation: "required",
                                },
                                model: {
                                  value: _vm.payBox.tef.pinpadModel,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payBox.tef, "pinpadModel", $$v)
                                  },
                                  expression: "payBox.tef.pinpadModel",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.payBox.tef.connectionHttps
                        ? _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c("FormulateInput", {
                                    staticClass: "required",
                                    attrs: {
                                      id: "tef-config-connection-host",
                                      label: _vm.$t("Host"),
                                      validation: "required",
                                    },
                                    model: {
                                      value: _vm.payBox.tef.connectionHost,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payBox.tef,
                                          "connectionHost",
                                          $$v
                                        )
                                      },
                                      expression: "payBox.tef.connectionHost",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c("FormulateInput", {
                                    staticClass: "required",
                                    attrs: {
                                      id: "tef-config-connection-port",
                                      type: "number",
                                      label: _vm.$t("Porta"),
                                      validation: "required",
                                    },
                                    model: {
                                      value: _vm.payBox.tef.connectionPort,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payBox.tef,
                                          "connectionPort",
                                          $$v
                                        )
                                      },
                                      expression: "payBox.tef.connectionPort",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c("FormulateInput", {
                                    staticClass: "required",
                                    attrs: {
                                      id: "tef-config-connection-protocol",
                                      type: "vue-select",
                                      label: _vm.$t("Protocolo"),
                                      placeholder: _vm.$t(
                                        "Selecione o protocolo"
                                      ),
                                      options: _vm.protocolOptions,
                                      validation: "required",
                                    },
                                    model: {
                                      value: _vm.payBox.tef.connectionProtocol,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payBox.tef,
                                          "connectionProtocol",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "payBox.tef.connectionProtocol",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.payBox.tef.connectionHttps
                        ? _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c("FormulateInput", {
                                    staticClass: "required",
                                    attrs: {
                                      id: "tef-config-auth-user",
                                      label: _vm.$t("Usuário"),
                                      validation: "required",
                                    },
                                    model: {
                                      value: _vm.payBox.tef.authUsername,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payBox.tef,
                                          "authUsername",
                                          $$v
                                        )
                                      },
                                      expression: "payBox.tef.authUsername",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c("FormulateInput", {
                                    staticClass: "required",
                                    attrs: {
                                      id: "tef-config-auth-pass",
                                      label: _vm.$t("Senha"),
                                      validation: "required",
                                    },
                                    model: {
                                      value: _vm.payBox.tef.authPassword,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payBox.tef,
                                          "authPassword",
                                          $$v
                                        )
                                      },
                                      expression: "payBox.tef.authPassword",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c("FormulateInput", {
                                    staticClass: "required mb-0",
                                    attrs: {
                                      id: "tef-config-auth-doc",
                                      label: _vm.$t("CNPJ"),
                                      mask: ["##.###.###/####-##"],
                                      type: _vm.isProduction ? "label" : "text",
                                      filter: "cpfCnpj",
                                      validation: "required",
                                      "aria-describedby":
                                        "tef-config-auth-doc-helper-block",
                                    },
                                    model: {
                                      value: _vm.payBox.tef.authDocument,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payBox.tef,
                                          "authDocument",
                                          $$v
                                        )
                                      },
                                      expression: "payBox.tef.authDocument",
                                    },
                                  }),
                                  _c(
                                    "small",
                                    {
                                      staticClass: "form-text",
                                      attrs: {
                                        id: "tef-config-auth-doc-helper-block",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("storeName")(
                                              _vm.payBox.store
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("e-button", {
                                    attrs: {
                                      id: "authenticate-tef-btn",
                                      variant: "outline-primary",
                                      text: _vm.$t("Autenticar"),
                                      disabled: _vm.busy,
                                    },
                                    on: { click: _vm.authenticate },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("FormulateInput", {
                                    staticClass: "required",
                                    attrs: {
                                      id: "authenticate-tef-result",
                                      type: "textarea",
                                      disabled: "true",
                                      label: _vm.$t("Resultado Autenticação"),
                                      validation: "authenticateValid",
                                      "validation-rules": {
                                        authenticateValid: function () {
                                          return (
                                            _vm.payBox.tef.connectionHttps &&
                                            !_vm.results.authenticate.error
                                          )
                                        },
                                      },
                                      "validation-messages": {
                                        authenticateValid: _vm.$t(
                                          "Resultado Autenticação deve ser sucesso"
                                        ),
                                      },
                                    },
                                    model: {
                                      value: _vm.results.authenticate.msg,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.results.authenticate,
                                          "msg",
                                          $$v
                                        )
                                      },
                                      expression: "results.authenticate.msg",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c("p", { staticClass: "h4" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("Configuração PDV")) + " "
                              ),
                            ]),
                          ]),
                          _c(
                            "b-col",
                            { attrs: { md: "4" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "tef-config-business-code",
                                  label: _vm.$t("Código do estabelecimento"),
                                  validation: "required",
                                  disabled: _vm.payBox.tef.connectionHttps,
                                },
                                model: {
                                  value: _vm.payBox.tef.businessCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox.tef,
                                      "businessCode",
                                      $$v
                                    )
                                  },
                                  expression: "payBox.tef.businessCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "4" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "tef-config-store-code",
                                  label: _vm.$t("Código da loja"),
                                  validation: "required",
                                  disabled: _vm.payBox.tef.connectionHttps,
                                },
                                model: {
                                  value: _vm.payBox.tef.storeCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payBox.tef, "storeCode", $$v)
                                  },
                                  expression: "payBox.tef.storeCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "4" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "tef-config-terminal-code",
                                  label: _vm.$t("Código da terminal"),
                                  validation: "required",
                                  disabled: _vm.payBox.tef.connectionHttps,
                                },
                                model: {
                                  value: _vm.payBox.tef.terminalCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox.tef,
                                      "terminalCode",
                                      $$v
                                    )
                                  },
                                  expression: "payBox.tef.terminalCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c("p", { staticClass: "h4" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("Sistema Comercial")) + " "
                              ),
                            ]),
                          ]),
                          _c(
                            "b-col",
                            { attrs: { md: "6" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "tef-config-system-name",
                                  label: _vm.$t("Nome do sistema"),
                                  validation: "required",
                                },
                                model: {
                                  value: _vm.payBox.tef.systemName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payBox.tef, "systemName", $$v)
                                  },
                                  expression: "payBox.tef.systemName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "6" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "tef-config-system-version",
                                  label: _vm.$t("Versão do sistema"),
                                  validation: "required",
                                },
                                model: {
                                  value: _vm.payBox.tef.systemVersion,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox.tef,
                                      "systemVersion",
                                      $$v
                                    )
                                  },
                                  expression: "payBox.tef.systemVersion",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c("p", { staticClass: "h4" }, [
                              _vm._v(" " + _vm._s(_vm.$t("Outros")) + " "),
                            ]),
                          ]),
                          _c(
                            "b-col",
                            { attrs: { md: "6" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "tef-config-enabled",
                                  type: "switch",
                                  label: _vm.$t("Habilita TEF"),
                                },
                                model: {
                                  value: _vm.payBox.tef.enabled,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payBox.tef, "enabled", $$v)
                                  },
                                  expression: "payBox.tef.enabled",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "6" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "tef-config-restart-device",
                                  type: "switch",
                                  instructions: [
                                    {
                                      text: _vm.$t(
                                        "Com esta opção ativa em todas as operações relacionadas ao TEF iremos verificar se é necessário um restart do dispositivo PINPAD"
                                      ),
                                    },
                                    {
                                      text: _vm.$t(
                                        "Operação é o equivalente a função Desinstalar do Gerenciador de Dispositivos do Windows"
                                      ),
                                    },
                                    {
                                      text: _vm.$t(
                                        "Somente tem efeito no Windows"
                                      ),
                                    },
                                  ],
                                  label: _vm.$t(
                                    "Reiniciar dispositivo (Windows) caso necessário"
                                  ),
                                },
                                model: {
                                  value: _vm.payBox.tef.restartDevice,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox.tef,
                                      "restartDevice",
                                      $$v
                                    )
                                  },
                                  expression: "payBox.tef.restartDevice",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "6" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "tef-config-restart-client-mode",
                                  label: _vm.$t(
                                    "Reiniciar serviço (CTFClient)"
                                  ),
                                  type: "vue-select",
                                  options: _vm.availableRestartClientMode,
                                  clearable: false,
                                  validation: "required",
                                },
                                model: {
                                  value: _vm.payBox.tef.restartClientMode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox.tef,
                                      "restartClientMode",
                                      $$v
                                    )
                                  },
                                  expression: "payBox.tef.restartClientMode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "6" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "tef-config-warm-client-mode",
                                  label: _vm.$t("Inicializar client"),
                                  type: "vue-select",
                                  options: _vm.availableWarmClientMode,
                                  clearable: false,
                                  instruction: _vm.$t(
                                    "Esta opção configura o Inicializar Client antes de uma operação de venda, adiantando esta operação para evitá-la nas transações de pagamentos que podem algumas vezes gerar erro"
                                  ),
                                  validation: "required",
                                },
                                model: {
                                  value: _vm.payBox.tef.warmClientMode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox.tef,
                                      "warmClientMode",
                                      $$v
                                    )
                                  },
                                  expression: "payBox.tef.warmClientMode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "4" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "tef-config-log",
                                  type: "switch",
                                  label: _vm.$t("Habilita Log"),
                                },
                                model: {
                                  value: _vm.payBox.tef.log,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payBox.tef, "log", $$v)
                                  },
                                  expression: "payBox.tef.log",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "4" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "tef-config-cryptography",
                                  type: "switch",
                                  label: _vm.$t("Habilita Criptografia"),
                                },
                                model: {
                                  value: _vm.payBox.tef.cryptography,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox.tef,
                                      "cryptography",
                                      $$v
                                    )
                                  },
                                  expression: "payBox.tef.cryptography",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "4" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "tef-config-interactive",
                                  type: "switch",
                                  label: _vm.$t("Modo Interativo"),
                                },
                                model: {
                                  value: _vm.payBox.tef.interactive,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payBox.tef, "interactive", $$v)
                                  },
                                  expression: "payBox.tef.interactive",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-card-actions",
                    {
                      attrs: { "no-actions": "", title: _vm.$t("Testes TEF") },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("e-button", {
                                attrs: {
                                  id: "restart-services-tef-btn",
                                  variant: "outline-primary",
                                  text: _vm.$t("Reiniciar serviço (PINPAD)"),
                                },
                                on: { click: _vm.stRestartService },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("e-button", {
                                attrs: {
                                  id: "auto-configuration-pinpad-tef-btn",
                                  variant: "outline-primary",
                                  text: _vm.$t(
                                    "Configuracao Automática do Pinpad (Windows)"
                                  ),
                                  disabled: _vm.busy,
                                },
                                on: { click: _vm.autoConfigurationPinpad },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "auto-configuration-pinpad-result",
                                  type: "textarea",
                                  disabled: "true",
                                  label: _vm.$t(
                                    "Resultado Configuracao Automática do Pinpad"
                                  ),
                                },
                                model: {
                                  value:
                                    _vm.results.autoConfigurationPinpad.msg,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.results.autoConfigurationPinpad,
                                      "msg",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "results.autoConfigurationPinpad.msg",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("e-button", {
                                attrs: {
                                  id: "initialize-tef-btn",
                                  variant: "outline-primary",
                                  text: _vm.$t("Inicializar client"),
                                  disabled: _vm.busy,
                                },
                                on: { click: _vm.initialize },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "initialize-tef-result",
                                  type: "textarea",
                                  disabled: "true",
                                  label: _vm.$t("Resultado Inicializar"),
                                  validation: "initializeValid",
                                  "validation-rules": {
                                    initializeValid: function () {
                                      return !_vm.results.initialize.error
                                    },
                                  },
                                  "validation-messages": {
                                    initializeValid: _vm.$t(
                                      "Resultado Inicializar deve ser sucesso"
                                    ),
                                  },
                                },
                                model: {
                                  value: _vm.results.initialize.msg,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.results.initialize, "msg", $$v)
                                  },
                                  expression: "results.initialize.msg",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("e-button", {
                                attrs: {
                                  id: "refresh-tables-tef-btn",
                                  variant: "outline-primary",
                                  text: _vm.$t("Atualizar Tabelas"),
                                  disabled: _vm.busy,
                                },
                                on: { click: _vm.refreshTables },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "refresh-tables-result",
                                  type: "textarea",
                                  disabled: "true",
                                  label: _vm.$t("Resultado Atualizar Tabelas"),
                                },
                                model: {
                                  value: _vm.results.refreshTables.msg,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.results.refreshTables,
                                      "msg",
                                      $$v
                                    )
                                  },
                                  expression: "results.refreshTables.msg",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("e-button", {
                                attrs: {
                                  id: "print-last-tef-btn",
                                  variant: "outline-primary",
                                  text: _vm.$t("Reimprimir último recibo"),
                                  disabled: _vm.busy,
                                },
                                on: { click: _vm.printLastReceipt },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "print-last-result",
                                  type: "textarea",
                                  disabled: "true",
                                  label: _vm.$t(
                                    "Resultado Reimprimir último recibo"
                                  ),
                                },
                                model: {
                                  value: _vm.results.printLastReceipt.msg,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.results.printLastReceipt,
                                      "msg",
                                      $$v
                                    )
                                  },
                                  expression: "results.printLastReceipt.msg",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c("p", { staticClass: "h4" }, [
                              _vm._v(" " + _vm._s(_vm.headerTefMessages) + " "),
                            ]),
                          ]),
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("b-table", {
                                ref: "tef-messages-table",
                                attrs: {
                                  "show-empty": "",
                                  responsive: "",
                                  striped: "",
                                  hover: "",
                                  fields: _vm.tefMessagesFields,
                                  items: _vm.tefLastMessages,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }