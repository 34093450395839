var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "FormulateForm",
        { ref: "formPayboxData", attrs: { name: "formPayboxData" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  !_vm.payBox.finishedSetup
                    ? _c(
                        "b-card-actions",
                        {
                          attrs: {
                            title: _vm.$t("Configuração"),
                            "show-loading": _vm.fetching,
                            "no-actions": "",
                          },
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "8" } },
                                [
                                  _c("e-store-combo", {
                                    attrs: {
                                      id: "manage_pay_box_sidebar-store_id",
                                      "only-active-options": "",
                                    },
                                    on: { input: _vm.onSelectStore },
                                    model: {
                                      value: _vm.payBox.storeId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.payBox, "storeId", $$v)
                                      },
                                      expression: "payBox.storeId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c("FormulateInput", {
                                    staticClass: "required",
                                    attrs: {
                                      id: "pay-box-id",
                                      type: "vue-select",
                                      label: _vm.$t("Caixa"),
                                      placeholder: _vm.$t(
                                        "Selecione um caixa disponível"
                                      ),
                                      options: _vm.getAvailablePayBoxesOptions,
                                      validation: "required",
                                    },
                                    on: { input: _vm.onSelectPayBox },
                                    model: {
                                      value: _vm.payBox.id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.payBox, "id", $$v)
                                      },
                                      expression: "payBox.id",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isBarStore
                    ? _c(
                        "b-card-actions",
                        {
                          attrs: {
                            title: _vm.$t("Configuração Bar"),
                            "sub-title": _vm.$t(
                              "Configurações utilizadas no PDV para lojas do tipo Bar"
                            ),
                            "show-loading": _vm.fetching,
                            "no-actions": "",
                          },
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "config-bar_print_tab_event",
                                      type: "switch",
                                      label: _vm.$t(
                                        "Imprimir solicitação de fechamento"
                                      ),
                                      instructions: [
                                        {
                                          text: _vm.$t(
                                            "Se habilitado, o PDV busca e imprime as solicitações de fechamento das comandas pendentes."
                                          ),
                                        },
                                        {
                                          text: _vm.$t(
                                            "Solicitação de fechamento é feita quando o garçom deseja que seja impresso os produtos e valores a serem pagos da comanda."
                                          ),
                                        },
                                      ],
                                    },
                                    model: {
                                      value: _vm.payBox.barPrintTabEvent,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payBox,
                                          "barPrintTabEvent",
                                          $$v
                                        )
                                      },
                                      expression: "payBox.barPrintTabEvent",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "config-bar_print_partial_consumption",
                                      type: "switch",
                                      label: _vm.$t(
                                        "Imprimir pedidos de comandas"
                                      ),
                                      instructions: [
                                        {
                                          text: _vm.$t(
                                            "Se habilitado, o PDV busca e imprime os pedidos criados pelos garçons."
                                          ),
                                        },
                                        {
                                          text: _vm.$t(
                                            "No sistema, os pedidos de comandas também podem aparecer como consumo parcial."
                                          ),
                                        },
                                      ],
                                    },
                                    model: {
                                      value:
                                        _vm.payBox.barPrintPartialConsumption,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payBox,
                                          "barPrintPartialConsumption",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "payBox.barPrintPartialConsumption",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c(
                    "b-card-actions",
                    {
                      attrs: {
                        "action-refresh": "",
                        title: _vm.$t("Dados da estação de trabalho"),
                        "show-loading": _vm.fetching,
                      },
                      on: { refresh: _vm.fetchWorkstationInfo },
                    },
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c("FormulateInput", {
                            staticClass: "required",
                            attrs: {
                              id: "pay_box_data_tab-mac",
                              type: "label",
                              label: _vm.$t("Endereço MAC"),
                              validation: "validMac",
                              disabled: "true",
                              "validation-rules": {
                                validMac: function (ref) {
                                  var value = ref.value

                                  return _vm.checkMacIsValid(value)
                                },
                              },
                            },
                            model: {
                              value: _vm.payBox.macAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.payBox, "macAddress", $$v)
                              },
                              expression: "payBox.macAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.payBox.number
                        ? _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  type: "label",
                                  label: _vm.$t("Número do caixa"),
                                },
                                model: {
                                  value: _vm.payBox.number,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payBox, "number", $$v)
                                  },
                                  expression: "payBox.number",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.payBox.description
                        ? _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  type: "label",
                                  label: _vm.$t("Descrição do caixa"),
                                },
                                model: {
                                  value: _vm.payBox.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payBox, "description", $$v)
                                  },
                                  expression: "payBox.description",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.payBox.store
                        ? _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("FormulateInput", {
                                attrs: { type: "label", label: _vm.$t("Loja") },
                                model: {
                                  value: _vm.payBox.store.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payBox.store, "name", $$v)
                                  },
                                  expression: "payBox.store.name",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.payBox.store
                        ? _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  type: "label",
                                  label: _vm.$t("CNPJ da loja"),
                                },
                                model: {
                                  value: _vm.payBox.store.document,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payBox.store, "document", $$v)
                                  },
                                  expression: "payBox.store.document",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.payBox.store
                        ? _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  type: "label",
                                  label: _vm.$t("Inscrição Estadual da loja"),
                                },
                                model: {
                                  value: _vm.payBox.store.stateRegistration,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox.store,
                                      "stateRegistration",
                                      $$v
                                    )
                                  },
                                  expression: "payBox.store.stateRegistration",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c(
                    "b-card-actions",
                    {
                      attrs: {
                        "no-actions": "",
                        title: _vm.$t("Testes Agente"),
                      },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("e-button", {
                                attrs: {
                                  id: "pay_box_data_tab-agent_test_btn",
                                  variant: "outline-primary",
                                  text: _vm.$t("Teste ping/pong"),
                                },
                                on: { click: _vm.testAgent },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "pay_box_data_tab-agent_test_result",
                                  type: "textarea",
                                  disabled: "true",
                                  label: _vm.$t("Resultado teste Agente"),
                                  validation: "agentTestValid",
                                  "validation-rules": {
                                    agentTestValid: function () {
                                      return !_vm.results.agentTest.error
                                    },
                                  },
                                  "validation-messages": {
                                    agentTestValid: _vm.$t(
                                      "Resultado teste do Agente deve ser sucesso"
                                    ),
                                  },
                                },
                                model: {
                                  value: _vm.results.agentTest.msg,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.results.agentTest, "msg", $$v)
                                  },
                                  expression: "results.agentTest.msg",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }