<template>
  <div>
    <b-row>
      <b-col md="12">
        <e-button
          id="pay_box_printer_tab-print_test_btn"
          variant="outline-primary"
          :text="$t('Impressão de teste')"
          :disabled="busy"
          @click="testPrint"
        />
      </b-col>

      <b-col md="12">
        <FormulateInput
          id="pay_box_printer_tab-print_test_result"
          v-model="results.printTest.msg"
          name="printTest"
          type="textarea"
          disabled="true"
          class="required"
          :label="$t('Resultado Impressão de teste')"
          validation="printTestValid"
          :validation-rules="{ printTestValid: () => !results.printTest.error }"
          :validation-messages="{
            printTestValid: $t('Resultado Impressão de teste deve ser sucesso'),
          }"
        />
      </b-col>

      <b-col md="12">
        <FormulateInput
          id="pay_box_printer_tab-random_number_check"
          v-model="randomNumberCheck"
          name="randomNumberCheck"
          class="required"
          type="text"
          :label="$t('Código impresso')"
          validation="required|printTestCheck"
          :validation-rules="{ printTestCheck: () => randomNumberCheck == randomNumber }"
          :validation-messages="{
            printTestCheck: $t('Último código impresso de teste deve ser informado'),
          }"
        />
      </b-col>
    </b-row>
    <br>
    <br>
    <b-row v-if="showCashDrawerTest">
      <b-col md="12">
        <e-button
          id="open-cash-drawer-btn"
          variant="outline-primary"
          :text="$t('Abrir gaveta')"
          :disabled="busy"
          @click="openCashDrawer"
        />
      </b-col>

      <b-col md="12">
        <FormulateInput
          id="check-cash-drawer-result"
          v-model="results.cashDrawer.msg"
          name="cashDrawer"
          type="textarea"
          disabled="true"
          :label="$t('Resultado Abrir Gaveta')"
        />
      </b-col>
    </b-row>

    <b-row v-if="showTagsList">
      <b-col md="12">
        <e-button
          id="open-printer-tags-btn"
          variant="outline-primary"
          :text="$t('Listar tags')"
          :disabled="busy"
          @click="getPrintersTags"
        />
      </b-col>

      <b-col md="12">
        <FormulateInput
          id="check-printer-tags-result"
          v-model="results.printerTags.msg"
          name="printerTags"
          type="textarea"
          disabled="true"
          :label="$t('Resultado Listar tags')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import EButton from '@/views/components/EButton.vue'
import { toMiliseconds } from '@/utils/promise-util'

export default {
  components: {
    EButton,
    BRow,
    BCol,
  },

  props: {
    printerType: {
      type: String,
      required: true,
      validator(value) {
        return ['Local', 'Expedition'].includes(value)
      },
    },
    showCashDrawerTest: {
      type: Boolean,
      default: true,
    },

    showTagsList: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      randomNumber: null,
      randomNumberCheck: null,
      busy: false,
      results: {
        cashDrawer: {
          msg: null,
          error: false,
        },
        printerTags: {
          msg: null,
          error: false,
        },
        printTest: {
          msg: null,
          error: true,
        },
      },
    }
  },
  computed: {
    ...mapGetters('pages/pdv/payBoxConfiguration', [
      'thermalPrinterAgentConfigPayload',
      'thermalPrinterExpeditionAgentConfigPayload',
    ]),

    getConfigPayload() {
      if (this.printerType === 'Expedition') return this.thermalPrinterExpeditionAgentConfigPayload
      return this.thermalPrinterAgentConfigPayload
    },
  },

  mounted() {},

  methods: {
    openCashDrawer() {
      try {
        this.busy = true
        this.results.cashDrawer.msg = 'Executando...'
        window.electronAPI.openCashDrawer(this.getConfigPayload)
        this.results.cashDrawer.error = false
        this.results.cashDrawer.msg = 'ok'
        this.busy = false
      } catch (err) {
        console.error('cashDrawer:', err)
        this.busy = false
        this.results.cashDrawer.error = true
        this.results.cashDrawer.msg = err
      }
    },
    async testPrint() {
      const max = 999
      const min = 100
      this.randomNumber = Math.floor(Math.random() * (max - min) + min)
      console.debug('testPrint | randomNumber', this.randomNumber)

      const textValue = [
        '</ce><a>**** Teste de Configuração PDV ****</a>',
        '</ce>Teste de impressora não fiscal térmica',
        '</linha_simples>',
        '</pular_linhas>',
        '</linha_dupla>',
        'Para finalizar a configuração informe o código:',
        `<a><n>${this.randomNumber}</n></a>`,
        '</linha_dupla>',
        '</ce>final de impressão',
        '</corte>',
      ].join('\n')

      try {
        this.busy = true
        this.results.printTest.msg = 'Executando...'
        const result = await window.electronAPI.print(
          {
            ...this.getConfigPayload,
            request: {
              printTexts: [
                {
                  printText: textValue,
                },
              ],
            },
          },
          toMiliseconds({ sec: 20 })
        )
        this.results.printTest.error = false
        this.results.printTest.msg = result || 'ok'
        this.busy = false
      } catch (err) {
        console.error('printTest:', err)
        this.busy = false
        this.results.printTest.error = true
        this.results.printTest.msg = err
      }
    },
    async getPrintersTags() {
      try {
        this.busy = true
        this.results.printerTags.msg = 'Executando...'
        const result = await window.electronAPI.printerTags(this.getConfigPayload)
        this.results.printerTags.error = false
        this.results.printerTags.msg = result.toString().replaceAll(',<', '\n<')
        this.busy = false
      } catch (err) {
        console.error('printerTags:', err)
        this.busy = false
        this.results.printerTags.error = true
        this.results.printerTags.msg = err
      }
    },
  },
}
</script>
