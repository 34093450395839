<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card-actions no-actions>
          <div class="d-flex align-items-center justify-content-center">
            <p class="h4">
              {{
                $t(
                  'Antes de iniciar a configuração do PDV, confira se todos os equipamentos estão conectados no computador.'
                )
              }}
            </p>
          </div>
        </b-card-actions>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card-actions
          no-actions
          :title="$t('Status')"
        >
          <payBox-status />

          <b-link @click="openDevTools">
            <feather-icon
              icon="SettingsIcon"
              size="21"
            />
            {{ $t('Abrir dev tools') }}
          </b-link>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BLink } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import PayBoxStatus from '../PayBoxStatus.vue'

export default {
  components: {
    BCardActions,
    PayBoxStatus,
    BRow,
    BCol,
    BLink,
  },
  methods: {
    async openDevTools() {
      await window.electronAPI.system.openDevTools()
    },
  },
}
</script>

<style lang="scss" scoped></style>
