var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("e-button", {
                attrs: {
                  id: "operational-check-sat-btn",
                  variant: "outline-primary",
                  text: _vm.$t("Consultar Status Operacional"),
                  disabled: _vm.busy,
                },
                on: { click: _vm.checkSatStatus },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "operational-check-sat-result",
                  type: "textarea",
                  disabled: "true",
                  label: _vm.$t("Resultado Consultar Status SAT"),
                  validation: "checkSatStatusValid",
                  "validation-rules": {
                    checkSatStatusValid: function () {
                      return !_vm.results.checkSatStatus.error
                    },
                  },
                  "validation-messages": {
                    checkSatStatusValid: _vm.$t(
                      "Resultado Consultar Status SAT deve ser sucesso"
                    ),
                  },
                },
                model: {
                  value: _vm.results.checkSatStatus.msg,
                  callback: function ($$v) {
                    _vm.$set(_vm.results.checkSatStatus, "msg", $$v)
                  },
                  expression: "results.checkSatStatus.msg",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c("FormulateInput", {
                attrs: {
                  id: "operational-check-sat-layout-version",
                  type: "text",
                  disabled: "true",
                  label: _vm.$t("Versão do Layout"),
                },
                model: {
                  value: _vm.payBox.sat.layoutVersion,
                  callback: function ($$v) {
                    _vm.$set(_vm.payBox.sat, "layoutVersion", $$v)
                  },
                  expression: "payBox.sat.layoutVersion",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("e-button", {
                attrs: {
                  id: "end-to-end-sat-btn",
                  variant: "outline-primary",
                  text: _vm.$t("Realizar Teste Fim a Fim"),
                  disabled: _vm.busy,
                },
                on: { click: _vm.endToEndTest },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "end-to-end-sat-result",
                  type: "textarea",
                  disabled: "true",
                  label: _vm.$t("Resultado Teste Fim a Fim"),
                  validation: "endToEndTestValid",
                  "validation-rules": {
                    endToEndTestValid: function () {
                      return !_vm.results.endToEndTest.error
                    },
                  },
                  "validation-messages": {
                    endToEndTestValid: _vm.$t(
                      "Resultado Teste Fim a Fim deve ser sucesso"
                    ),
                  },
                },
                model: {
                  value: _vm.results.endToEndTest.msg,
                  callback: function ($$v) {
                    _vm.$set(_vm.results.endToEndTest, "msg", $$v)
                  },
                  expression: "results.endToEndTest.msg",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "end-to-end-sat-result_number_check",
                  type: "text",
                  label: _vm.$t(
                    "Código impresso no campo Observação do extrato do SAT"
                  ),
                  validation: "required|endToEndTestCheck",
                  "validation-rules": {
                    endToEndTestCheck: function () {
                      return _vm.randomNumberCheck == _vm.randomNumber
                    },
                  },
                  "validation-messages": {
                    endToEndTestCheck: _vm.$t(
                      "Último código impresso no campo Observação deve ser informado"
                    ),
                  },
                },
                model: {
                  value: _vm.randomNumberCheck,
                  callback: function ($$v) {
                    _vm.randomNumberCheck = $$v
                  },
                  expression: "randomNumberCheck",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("e-button", {
                attrs: {
                  id: "update-software-sat-btn",
                  variant: "outline-primary",
                  text: _vm.$t("Atualizar software básico"),
                  disabled: _vm.busy,
                },
                on: { click: _vm.updateBasicSoftwareSat },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "update-software-sat-result",
                  type: "textarea",
                  disabled: "true",
                  label: _vm.$t("Resultado Atualizar software básico"),
                },
                model: {
                  value: _vm.results.updateBasicSoftwareSat.msg,
                  callback: function ($$v) {
                    _vm.$set(_vm.results.updateBasicSoftwareSat, "msg", $$v)
                  },
                  expression: "results.updateBasicSoftwareSat.msg",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("e-button", {
                attrs: {
                  id: "logs-sat-btn",
                  variant: "outline-primary",
                  text: _vm.$t("Extrair logs"),
                  disabled: _vm.busy,
                },
                on: { click: _vm.extractLogsSat },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "logs-sat-result",
                  type: "textarea",
                  disabled: "true",
                  label: _vm.$t("Resultado Extrair logs"),
                },
                model: {
                  value: _vm.results.extractLogsSat.msg,
                  callback: function ($$v) {
                    _vm.$set(_vm.results.extractLogsSat, "msg", $$v)
                  },
                  expression: "results.extractLogsSat.msg",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("e-button", {
                attrs: {
                  id: "unlock-sat-btn",
                  variant: "outline-primary",
                  text: _vm.$t("Desbloquear SAT"),
                  disabled: _vm.busy,
                },
                on: { click: _vm.unlockSat },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "unlock-sat-result",
                  type: "textarea",
                  disabled: "true",
                  label: _vm.$t("Resultado Desbloquear SAT"),
                },
                model: {
                  value: _vm.results.unlockSat.msg,
                  callback: function ($$v) {
                    _vm.$set(_vm.results.unlockSat, "msg", $$v)
                  },
                  expression: "results.unlockSat.msg",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }