<template>
  <div>
    <FormulateForm
      ref="formCoupon"
      name="formCoupon"
    >
      <b-row>
        <b-col md="6">
          <b-card-actions
            no-actions
            :title="$t('Configuração da NFCe')"
          >
            <b-row>
              <b-col md="4">
                <FormulateInput
                  id="pay_box_nfce_tab-preferred_invoice_type"
                  v-model="payBox.preferredInvoiceType"
                  type="vue-select"
                  :label="$t('Documento fiscal padrão')"
                  :placeholder="$t('Selecione o documento fiscal padrão')"
                  :options="payBox.availableInvoiceTypes"
                  class="required"
                  validation="required"
                />
              </b-col>
              <b-col
                v-if="!isProduction"
                md="4"
              >
                <FormulateInput
                  id="pay_box_nfce_tab-submit_method"
                  v-model="payBox.nfce.submitMethod"
                  type="vue-select"
                  :label="$t('Forma de emissão')"
                  :placeholder="$t('Forma de emissão')"
                  :options="submitMethodOptions"
                  :instruction="
                    $t(
                      'Caso selecionado \'Contingência\' e loja for de SP, será utilizado a forma EPEC'
                    )
                  "
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <FormulateInput
                  id="pay_box_nfce_tab-id_security_code"
                  v-model="payBox.nfce.idSecurityCode"
                  class="required"
                  type="text"
                  :label="$t('Id do código de segurança')"
                  validation="required"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <FormulateInput
                  id="pay_box_nfce_tab-token_security_code"
                  v-model="payBox.nfce.tokenSecurityCode"
                  class="required"
                  type="text"
                  :label="$t('Token de segurança')"
                  validation="required"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <FormulateInput
                  id="pay_box_nfce_tab-serial_number"
                  v-model="payBox.nfce.serialNumber"
                  class="required"
                  type="number"
                  :label="$t('Número de Série')"
                  validation="required"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <FormulateInput
                  id="pay_box_nfce_tab-last_invoice_number"
                  v-model="payBox.nfce.lastInvoiceNumber"
                  class="required"
                  type="number"
                  :label="$t('Código da última NFC-e emitida')"
                  validation="required"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
    </FormulateForm>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { mapState, mapGetters } from 'vuex'
import { alerts } from '@/mixins'

const environment = window.env || 'local'
const isProduction = environment === 'production'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
  },
  mixins: [alerts],
  data() {
    return {
      isProduction,
      submitMethodOptions: [
        { value: 'NORMAL', label: 'Normal' },
        { value: 'CONTINGENCY', label: 'Contingência' },
      ],
    }
  },
  computed: {
    ...mapState('pages/pdv/payBoxConfiguration', ['payBox']),
    ...mapGetters('pages/pdv/payBoxConfiguration', ['getAvailableSatOptions']),
  },
  methods: {
    skipStep() {
      this.payBox.nfce.skipped = true
    },
    canSkip() {
      return this.payBox.nfce.skipped
    },
    validateForm() {
      const valid = this.$refs.formCoupon.isValid
      if (!valid) {
        this.showInvalidDataMessage()
        this.$refs.formCoupon.showErrors()
      } else {
        this.payBox.nfce.skipped = false
      }

      return valid
    },
  },
}
</script>

<style lang="scss" scoped></style>
