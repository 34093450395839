var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("b-card-actions", { attrs: { "no-actions": "" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-center",
                  },
                  [
                    _c("p", { staticClass: "h4" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "Antes de iniciar a configuração do PDV, confira se todos os equipamentos estão conectados no computador."
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c(
                "b-card-actions",
                { attrs: { "no-actions": "", title: _vm.$t("Status") } },
                [
                  _c("payBox-status"),
                  _c(
                    "b-link",
                    { on: { click: _vm.openDevTools } },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "SettingsIcon", size: "21" },
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("Abrir dev tools")) + " "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }