<template>
  <div>
    <FormulateForm
      ref="formPayboxData"
      name="formPayboxData"
    >
      <b-row>
        <b-col md="6">
          <b-card-actions
            v-if="!payBox.finishedSetup"
            :title="$t('Configuração')"
            :show-loading="fetching"
            no-actions
          >
            <b-row>
              <b-col md="8">
                <e-store-combo
                  id="manage_pay_box_sidebar-store_id"
                  v-model="payBox.storeId"
                  only-active-options
                  @input="onSelectStore"
                />
              </b-col>

              <b-col md="4">
                <FormulateInput
                  id="pay-box-id"
                  v-model="payBox.id"
                  type="vue-select"
                  :label="$t('Caixa')"
                  :placeholder="$t('Selecione um caixa disponível')"
                  :options="getAvailablePayBoxesOptions"
                  class="required"
                  validation="required"
                  @input="onSelectPayBox"
                />
              </b-col>
            </b-row>
          </b-card-actions>

          <b-card-actions
            v-if="isBarStore"
            :title="$t('Configuração Bar')"
            :sub-title="$t('Configurações utilizadas no PDV para lojas do tipo Bar')"
            :show-loading="fetching"
            no-actions
          >
            <b-row>
              <b-col md="4">
                <FormulateInput
                  id="config-bar_print_tab_event"
                  v-model="payBox.barPrintTabEvent"
                  type="switch"
                  :label="$t('Imprimir solicitação de fechamento')"
                  :instructions="[
                    {
                      text: $t(
                        'Se habilitado, o PDV busca e imprime as solicitações de fechamento das comandas pendentes.'
                      ),
                    },
                    {
                      text: $t(
                        'Solicitação de fechamento é feita quando o garçom deseja que seja impresso os produtos e valores a serem pagos da comanda.'
                      ),
                    },
                  ]"
                />
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="config-bar_print_partial_consumption"
                  v-model="payBox.barPrintPartialConsumption"
                  type="switch"
                  :label="$t('Imprimir pedidos de comandas')"
                  :instructions="[
                    {
                      text: $t(
                        'Se habilitado, o PDV busca e imprime os pedidos criados pelos garçons.'
                      ),
                    },
                    {
                      text: $t(
                        'No sistema, os pedidos de comandas também podem aparecer como consumo parcial.'
                      ),
                    },
                  ]"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
        <b-col md="3">
          <b-card-actions
            action-refresh
            :title="$t('Dados da estação de trabalho')"
            :show-loading="fetching"
            @refresh="fetchWorkstationInfo"
          >
            <b-col md="12">
              <FormulateInput
                id="pay_box_data_tab-mac"
                v-model="payBox.macAddress"
                class="required"
                type="label"
                :label="$t('Endereço MAC')"
                validation="validMac"
                disabled="true"
                :validation-rules="{
                  validMac: ({ value }) => checkMacIsValid(value),
                }"
              />
            </b-col>

            <b-col
              v-if="payBox.number"
              md="12"
            >
              <FormulateInput
                v-model="payBox.number"
                type="label"
                :label="$t('Número do caixa')"
              />
            </b-col>

            <b-col
              v-if="payBox.description"
              md="12"
            >
              <FormulateInput
                v-model="payBox.description"
                type="label"
                :label="$t('Descrição do caixa')"
              />
            </b-col>

            <b-col
              v-if="payBox.store"
              md="12"
            >
              <FormulateInput
                v-model="payBox.store.name"
                type="label"
                :label="$t('Loja')"
              />
            </b-col>

            <b-col
              v-if="payBox.store"
              md="12"
            >
              <FormulateInput
                v-model="payBox.store.document"
                type="label"
                :label="$t('CNPJ da loja')"
              />
            </b-col>

            <b-col
              v-if="payBox.store"
              md="12"
            >
              <FormulateInput
                v-model="payBox.store.stateRegistration"
                type="label"
                :label="$t('Inscrição Estadual da loja')"
              />
            </b-col>
          </b-card-actions>
        </b-col>

        <b-col md="3">
          <b-card-actions
            no-actions
            :title="$t('Testes Agente')"
          >
            <b-row>
              <b-col md="12">
                <e-button
                  id="pay_box_data_tab-agent_test_btn"
                  variant="outline-primary"
                  :text="$t('Teste ping/pong')"
                  @click="testAgent"
                />
              </b-col>

              <b-col md="12">
                <FormulateInput
                  id="pay_box_data_tab-agent_test_result"
                  v-model="results.agentTest.msg"
                  type="textarea"
                  disabled="true"
                  class="required"
                  :label="$t('Resultado teste Agente')"
                  validation="agentTestValid"
                  :validation-rules="{ agentTestValid: () => !results.agentTest.error }"
                  :validation-messages="{
                    agentTestValid: $t('Resultado teste do Agente deve ser sucesso'),
                  }"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
    </FormulateForm>
  </div>
</template>

<script>
import { isMAC } from 'getmac'
import { alerts } from '@/mixins'
import { BRow, BCol } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import { mapState, mapActions, mapGetters } from 'vuex'

const environment = window.env || 'local'
const isProduction = environment === 'production'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    EButton,
    EStoreCombo,
  },
  mixins: [alerts],
  data() {
    return {
      fetching: false,
      results: {
        agentTest: {
          msg: null,
          error: true,
        },
      },
    }
  },
  computed: {
    ...mapState('pages/pdv/payBoxConfiguration', ['payBox']),
    ...mapGetters('pages/pdv/payBoxConfiguration', ['getAvailablePayBoxesOptions', 'isBarStore']),
  },
  async mounted() {
    await this.fetchWorkstationInfo()
  },
  methods: {
    ...mapActions('pages/pdv/payBoxConfiguration', ['fetchAvailablePayBoxes', 'fetchPayBox']),
    async fetchWorkstationInfo() {
      try {
        this.fetching = true
        if (isProduction || !this.payBox.macAddress) {
          const { macAddress } = await window.electronAPI.system.systemInfo()
          this.payBox.macAddress = macAddress
        }
      } catch (error) {
        this.showGenericError({ error })
      } finally {
        this.fetching = false
      }
    },
    async onSelectStore() {
      try {
        this.fetching = true
        await this.fetchAvailablePayBoxes()
      } catch (error) {
        this.showGenericError({ error })
      } finally {
        this.fetching = false
      }
    },

    async onSelectPayBox() {
      try {
        this.fetching = true
        await this.fetchPayBox()
      } catch (error) {
        this.showGenericError({ error })
      } finally {
        this.fetching = false
      }
    },
    checkMacIsValid(val) {
      return isMAC(val)
    },
    validateForm() {
      const valid = this.$refs.formPayboxData.isValid
      if (!valid) {
        this.showInvalidDataMessage()
        this.$refs.formPayboxData.showErrors()
      }

      return valid
    },
    async testAgent() {
      try {
        this.results.agentTest.msg = 'Executando...'
        const result = await window.electronAPI.pingPongUsingAgent('ping')
        this.results.agentTest.error = false
        this.results.agentTest.msg = result
      } catch (err) {
        console.error('agentTest:', err)
        this.results.agentTest.error = true
        this.results.agentTest.msg = err
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
