var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "FormulateForm",
        { ref: "formSat", attrs: { name: "formSat" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-card-actions",
                    {
                      attrs: {
                        "no-actions": "",
                        title: _vm.$t("Configuração do SAT/MFE"),
                      },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "4" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "pay_box_sat_tab-preferred_invoice_type",
                                  type: "vue-select",
                                  label: _vm.$t("Documento fiscal padrão"),
                                  placeholder: _vm.$t(
                                    "Selecione o documento fiscal padrão"
                                  ),
                                  options: _vm.payBox.availableInvoiceTypes,
                                  validation: "required",
                                },
                                model: {
                                  value: _vm.payBox.preferredInvoiceType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox,
                                      "preferredInvoiceType",
                                      $$v
                                    )
                                  },
                                  expression: "payBox.preferredInvoiceType",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "4" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "pay_box_sat_tab-sat_model",
                                  type: "vue-select",
                                  label: _vm.$t("Modelo"),
                                  placeholder: _vm.$t("Selecione um modelo"),
                                  options: _vm.getAvailableSatOptions,
                                  validation: "required",
                                },
                                model: {
                                  value: _vm.payBox.sat.satModel,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payBox.sat, "satModel", $$v)
                                  },
                                  expression: "payBox.sat.satModel",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "pay_box_sat_tab-activate_code",
                                  type: "text",
                                  label: _vm.$t("Código de ativação"),
                                  validation: "required",
                                },
                                model: {
                                  value: _vm.payBox.sat.activateCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox.sat,
                                      "activateCode",
                                      $$v
                                    )
                                  },
                                  expression: "payBox.sat.activateCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "pay_box_sat_tab-serial_number",
                                  type: "text",
                                  label: _vm.$t("Número de Série"),
                                  validation: "required",
                                },
                                model: {
                                  value: _vm.payBox.sat.serialNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox.sat,
                                      "serialNumber",
                                      $$v
                                    )
                                  },
                                  expression: "payBox.sat.serialNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("e-button", {
                                attrs: {
                                  id: "activate-sat-btn",
                                  variant: "outline-primary",
                                  text: _vm.$t("Ativar Sat"),
                                  disabled: _vm.busy,
                                },
                                on: { click: _vm.activateSat },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "activate-sat-result",
                                  type: "textarea",
                                  disabled: "true",
                                  label: _vm.$t("Resultado Ativar SAT"),
                                },
                                model: {
                                  value: _vm.results.activateSat.msg,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.results.activateSat,
                                      "msg",
                                      $$v
                                    )
                                  },
                                  expression: "results.activateSat.msg",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c("p", { staticClass: "h4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("Configuração de código de página")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c(
                            "b-col",
                            { attrs: { md: "4" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "pay_box_sat_tab-use_utf8",
                                  type: "switch",
                                  label: _vm.$t("É UTF-8 ?"),
                                },
                                model: {
                                  value: _vm.payBox.sat.useUtf8,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payBox.sat, "useUtf8", $$v)
                                  },
                                  expression: "payBox.sat.useUtf8",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.payBox.sat.useUtf8 !== true
                            ? _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "pay_box_sat_tab-page_code",
                                      type: "text",
                                      label: _vm.$t("Código de página"),
                                    },
                                    model: {
                                      value: _vm.payBox.sat.pageCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payBox.sat,
                                          "pageCode",
                                          $$v
                                        )
                                      },
                                      expression: "payBox.sat.pageCode",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c("p", { staticClass: "h4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("Assinatura Comercial")) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c(
                            "b-col",
                            { attrs: { md: "3" } },
                            [
                              _c("e-button", {
                                attrs: {
                                  id: "generate-signature-sat-btn",
                                  variant: "outline-primary",
                                  text: _vm.$t("Gerar Assinatura"),
                                  disabled: _vm.busy,
                                },
                                on: { click: _vm.generateSignatureSat },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "2" } },
                            [
                              _vm.results.generateSignatureSat.error === true
                                ? _c("FormulateInput", {
                                    attrs: {
                                      type: "label",
                                      label:
                                        _vm.results.generateSignatureSat.msg,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "8" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "pay_box_sat_tab-signature_commercial_app",
                                  type: "textarea",
                                  label: _vm.$t("Hash da Assinatura Comercial"),
                                  validation: "required",
                                },
                                model: {
                                  value: _vm.payBox.sat.signatureCommercialApp,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox.sat,
                                      "signatureCommercialApp",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "payBox.sat.signatureCommercialApp",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("e-button", {
                                attrs: {
                                  id: "associate-sat-btn",
                                  variant: "outline-primary",
                                  text: _vm.$t("Associar Assinatura"),
                                  disabled: _vm.busy,
                                },
                                on: { click: _vm.associateSat },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "associate-sat-result",
                                  type: "textarea",
                                  disabled: "true",
                                  label: _vm.$t(
                                    "Resultado Associar Assinatura"
                                  ),
                                },
                                model: {
                                  value: _vm.results.associateSat.msg,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.results.associateSat,
                                      "msg",
                                      $$v
                                    )
                                  },
                                  expression: "results.associateSat.msg",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c("strong", { staticClass: "h3" }, [
                              _vm._v(" " + _vm._s(_vm.$t("Impressão")) + " "),
                            ]),
                          ]),
                          _c(
                            "b-col",
                            { attrs: { md: "3" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "pay_box_sat_tab-margin_top",
                                  type: "text",
                                  label: _vm.$t("Margem superior"),
                                },
                                model: {
                                  value: _vm.payBox.sat.marginTop,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payBox.sat, "marginTop", $$v)
                                  },
                                  expression: "payBox.sat.marginTop",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "3" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "pay_box_sat_tab-margin_right",
                                  type: "text",
                                  label: _vm.$t("Margem direta"),
                                },
                                model: {
                                  value: _vm.payBox.sat.marginRight,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payBox.sat, "marginRight", $$v)
                                  },
                                  expression: "payBox.sat.marginRight",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "3" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "pay_box_sat_tab-margin_bottom",
                                  type: "text",
                                  label: _vm.$t("Margem inferior"),
                                },
                                model: {
                                  value: _vm.payBox.sat.marginBottom,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox.sat,
                                      "marginBottom",
                                      $$v
                                    )
                                  },
                                  expression: "payBox.sat.marginBottom",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "3" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "pay_box_sat_tab-margin_left",
                                  type: "text",
                                  label: _vm.$t("Margem esquerda"),
                                },
                                model: {
                                  value: _vm.payBox.sat.marginLeft,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payBox.sat, "marginLeft", $$v)
                                  },
                                  expression: "payBox.sat.marginLeft",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-card-actions",
                    {
                      attrs: {
                        "no-actions": "",
                        title: _vm.$t("Testes SAT/MFE"),
                      },
                    },
                    [_c("sat-manager")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }