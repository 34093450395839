import { render, staticRenderFns } from "./PayBoxStatus.vue?vue&type=template&id=d654ce32&"
import script from "./PayBoxStatus.vue?vue&type=script&lang=js&"
export * from "./PayBoxStatus.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/echope/echope-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d654ce32')) {
      api.createRecord('d654ce32', component.options)
    } else {
      api.reload('d654ce32', component.options)
    }
    module.hot.accept("./PayBoxStatus.vue?vue&type=template&id=d654ce32&", function () {
      api.rerender('d654ce32', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/pdv/pay-box-configuration/PayBoxStatus.vue"
export default component.exports